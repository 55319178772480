import BackgroundImg from "@/shared/assets/landing-page/bg-hero.svg";
import Image from "next/image";
import React from "react";

import EasyAccess from "@/shared/assets/landing-page/easy-access.svg";
import HighSecurity from "@/shared/assets/landing-page/high-security.svg";
import TimeEfficiency from "@/shared/assets/landing-page/time-efficiency.svg";
import { ArrowRight } from "lucide-react";

type HomeSectionProps = {
  handleGetStarted: () => void;
  refSection?: React.RefObject<HTMLElement>;
};

const HeroCard = [
  {
    icon: TimeEfficiency,
    title: "Time Efficiency",
    desc: "Save time with our quick signing process—no need to print or scan documents!",
  },
  {
    icon: HighSecurity,
    title: "High Security",
    desc: "Your documents are safe with encryption and unique QR codes that guarantee authenticity.",
  },
  {
    icon: EasyAccess,
    title: "Easy Access",
    desc: "Access your documents anytime, anywhere with secure cloud storage—perfect for remote work!",
  },
];

const HomeSection: React.FC<HomeSectionProps> = ({
  handleGetStarted,
  refSection,
}) => {
  return (
    <>
      <main className="mx-auto text-center" data-index="home" ref={refSection}>
        <div className="relative">
          <Image
            alt="Background"
            className="object-cover w-full h-[1080px] sm:h-[980px] md:h-[820px]"
            src={BackgroundImg}
          />

          <div className="transform lg:-translate-y-1/2 left-1/2 -translate-x-1/2 lg:top-1/2 top-36 w-full absolute">
            <div className="text-center">
              <h1 className="text-5xl md:text-6xl font-extrabold mb-6 font-neue text-[#1A1A1A]">
                Effort
                <span className="text-[#666666] text-5xl md:text-6xl font-medium font-neue">
                  less
                </span>{" "}
                and Safe
                <br />
                <span className="text-primary text-5xl md:text-6xl font-bold">
                  Digital Signatures
                </span>
              </h1>

              <p className="text-gray-600 mb-16 font-neue">
                Revolutionize the way you sign documents with our QR Code
                e-Signature.
              </p>

              <div
                onClick={handleGetStarted}
                className="inline-flex px-[20px] py-[10px] sm:px-[24px] sm:py-[14px] gap-1 sm:gap-2 rounded-full border-primary bg-white cursor-pointer border-4"
              >
                <div className="text-primary font-medium">Get Started</div>

                <ArrowRight className="text-primary" />
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-8 container mx-auto mt-[30px] md:mt-[90px]">
              {HeroCard.map((card, index) => (
                <div
                  key={index}
                  className="rounded-2xl py-3 md:py-6 px-4 md:px-8 flex flex-col gap-3 md:gap-6 text-left backdrop-opacity-5 backdrop-invert bg-white/65 shadow-sm"
                >
                  <Image
                    alt={card.title}
                    src={card.icon}
                    width={40}
                    height={40}
                  />
                  <h3 className="text-lg font-semibold">{card.title}</h3>
                  <p className="text-sm text-gray-600">{card.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default HomeSection;
