'use client';

import BenefitsSection from '@/features/landing-page/components/BanefitSection/BanefitSection';
import BannerSection from '@/features/landing-page/components/BannerSection/BannerSection';
import FAQSection from '@/features/landing-page/components/FAQSection/FAQSection';
import FeaturesSection from '@/features/landing-page/components/FeaturesSection/FeaturesSection';
import FooterSection from '@/features/landing-page/components/FooterSection/FooterSection';
import HomeSection from '@/features/landing-page/components/HomeSection/HomeSection';
import dynamic from 'next/dynamic';
import Cookies from 'js-cookie';

const Navbar = dynamic(
  () => import('@/features/landing-page/components/Navbar/Navbar'),
  {
    ssr: false,
  }
);
import TutorialSection from '@/features/landing-page/components/TutorialSection/TutorialSection';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/navigation';
import GetStartedSection from "../GetStartedSection/GetStartedSection";

export default function BaseContainerLandingPage() {
  const router = useRouter();
  const [refSelected, setRefSelected] = useState<string>("home");

  const sectionRefsHome = useRef<HTMLElement | null>(null);
  const sectionRefsFeature = useRef<HTMLElement | null>(null);
  const sectionRefsBenefits = useRef<HTMLElement | null>(null);
  const sectionRefsTutorial = useRef<HTMLElement | null>(null);

  const scrollToSection = (v: string) => {
    if (v === "home") {
      setRefSelected("home");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (v === "features") {
      setRefSelected("features");
      window.scrollTo({
        top: sectionRefsFeature.current
          ? sectionRefsFeature.current.offsetTop - 110
          : 0,
        behavior: "smooth",
      });
    } else if (v === "benefits") {
      setRefSelected("benefits");
      window.scrollTo({
        top: sectionRefsBenefits.current
          ? sectionRefsBenefits.current.offsetTop - 110
          : 0,
        behavior: "smooth",
      });
    } else if (v === "tutorial") {
      setRefSelected("tutorial");
      window.scrollTo({
        top: sectionRefsTutorial.current
          ? sectionRefsTutorial.current.offsetTop - 110
          : 0,
        behavior: "smooth",
      });
    }
  };

  const handleGetStarted = () => {
    const token = Cookies.get("sso-token");

    if (token) router.push("/dashboard");
    else router.push("/auth/login");
  };

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      const v = entry.target.getAttribute("data-index");

      if (entry.isIntersecting && v) setRefSelected(v);
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5,
    });

    if (sectionRefsHome.current) {
      observer.observe(sectionRefsHome.current);
    }

    if (sectionRefsFeature.current) {
      observer.observe(sectionRefsFeature.current);
    }

    if (sectionRefsBenefits.current) {
      observer.observe(sectionRefsBenefits.current);
    }

    if (sectionRefsTutorial.current) {
      observer.observe(sectionRefsTutorial.current);
    }

    return () => {
      if (sectionRefsHome.current) {
        observer.unobserve(sectionRefsHome.current);
      }

      if (sectionRefsBenefits.current) {
        observer.unobserve(sectionRefsBenefits.current);
      }

      if (sectionRefsFeature.current) {
        observer.unobserve(sectionRefsFeature.current);
      }

      if (sectionRefsTutorial.current) {
        observer.unobserve(sectionRefsTutorial.current);
      }
    };
  }, []);

  return (
    <div className="min-h-screen dark:bg-dark bg-white">
      <Navbar sectionRef={refSelected} handleClick={scrollToSection} />

      <HomeSection
        handleGetStarted={handleGetStarted}
        refSection={sectionRefsHome}
      />
      <BannerSection />

      <FeaturesSection refSection={sectionRefsFeature} />

      <BenefitsSection refSection={sectionRefsBenefits} />

      <TutorialSection refSection={sectionRefsTutorial} />
      <FAQSection />

      <GetStartedSection handleGetStarted={() => {}} />

      <FooterSection handleClickMenu={scrollToSection} />
    </div>
  );
}
