'use client';

import AdobeLogo from "@/shared/assets/logos/adobe-logo.png";
import AirTableLogo from "@/shared/assets/logos/airtable-logo.png";
import SpotifyLogo from "@/shared/assets/logos/spotify-logo.png";
import GoogleLogo from "@/shared/assets/logos/google-logo.png";
import PayPalLogo from "@/shared/assets/logos/paypal-logo.png";
import AmazonLogo from "@/shared/assets/logos/amazon-logo.png";

import AutoScroll from "embla-carousel-auto-scroll";
import useEmblaCarousel from "embla-carousel-react";
import Image from "next/image";
import "./BannerSection.css";

import type { StaticImageData } from "next/image";

type CompanyProps = {
  name: string;
  logo: StaticImageData | string;
};

const ListCompany: CompanyProps[] = [
  { name: "adobe", logo: AdobeLogo },
  { name: "airtable", logo: AirTableLogo },
  { name: "spotify", logo: SpotifyLogo },
  { name: "google", logo: GoogleLogo },
  { name: "paypal", logo: PayPalLogo },
  { name: "amazon", logo: AmazonLogo },

  { name: "adobe", logo: AdobeLogo },
  { name: "airtable", logo: AirTableLogo },
  { name: "spotify", logo: SpotifyLogo },
  { name: "google", logo: GoogleLogo },
  { name: "paypal", logo: PayPalLogo },
  { name: "amazon", logo: AmazonLogo },
];

const BannerSection = () => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    AutoScroll({ playOnInit: true, speed: 2 }),
  ]);

  return (
    <div className="embla py-16" ref={emblaRef}>
      <div className="embla__container">
        {ListCompany.map((company, index) => (
          <div className="embla__slide mx-[60px]" key={index}>
            <Image
              src={company.logo}
              alt={company.name}
              width={154}
              height={154}
              objectFit="contain"
              className="mx-auto grayscale opacity-90"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BannerSection;
