import React from "react";
import { Button } from "@/shared/shadcn/ui/button";
import Image from "next/image";
import GetStartedBG from "../../../../shared/assets/landing-page/bg-get-started.svg";

type GetStartedProps = {
  handleGetStarted: () => void;
  refSection?: React.RefObject<HTMLElement>;
};

const GetStartedSection: React.FC<GetStartedProps> = ({
  handleGetStarted,
  refSection,
}) => {
  return (
    <>
      <main
        className="container mx-auto px-4 pb-[40px] lg:pb-[64px] text-center"
        data-index="get-started"
        ref={refSection}
      >
        <div className="relative">
          <Image
            alt="Get Started"
            className="object-cover w-[100%] aspect-[27 / 10] rounded-[24px]"
            src={GetStartedBG}
          />

          <div className="absolute transform -translate-y-1/2 left-1/2 -translate-x-1/2 top-1/2 w-full text-center">
            <div>
              <h1
                className="text-[20px] sm:text-[30px] lg:text-[40px]"
                style={{
                  background:
                    "linear-gradient(180deg, #0A1C2E 30%, #B9C8D9 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  color: "transparent",
                  fontWeight: "bold",
                }}
              >
                Start Signing with Confidence
              </h1>
            </div>

            <p className="text-gray-600 mb-6 sm:mb-10 lg:mb-16 font-neue text-[12px] lg:text-[18px]">
              Get your documents signed faster, easier, and securely. Ready to
              streamline your workflow?
            </p>
            <Button
              className="p-[10px] sm:p-[20px] lg:p-[30px] text-[10px] lg:text-[14px]"
              style={{
                borderRadius: "30px",
              }}
              onClick={handleGetStarted}
            >
              Get Started
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};

export default GetStartedSection;
