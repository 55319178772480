import React from "react";
import Image from "next/image";
import { Button } from "@/shared/shadcn/ui/button";
import VideoIcon from "@/shared/assets/landing-page/vid.svg";
import FeatureBackground from "@/shared/assets/landing-page/feature.svg";

const listTutorial = [
  {
    id: 1,
    key: "Tutorial 01",
    label: "Uploading Documents",
    description:
      "Ready to get started? Uploading your documents is a breeze! In this tutorial, we’ll guide you step-by-step to ensure the process is quick and hassle-free. Let’s dive in so you can start signing your important documents right away!",
  },
  {
    id: 2,
    key: "Tutorial 02",
    label: "Signing Documents",
    description:
      "Once your document is uploaded, it’s time to sign! This tutorial will show you how to use various signature features, including images, stamps, and e-seals. Let’s make your document official!",
  },
  {
    id: 3,
    key: "Tutorial 03",
    label: "Sharing Documents",
    description:
      "Is your document signed? Now it’s time to share it! In this tutorial, you’ll learn how to quickly share documents with colleagues or clients. Let’s make collaboration easier!",
  },
];

interface TutorialSectionProps {
  refSection?: React.RefObject<HTMLElement>;
}

const TutorialSection: React.FC<TutorialSectionProps> = ({ refSection }) => {
  return (
    <section
      className="py-[42px] mb-[40px] md:mb-[64px] px-4 bg-[#1D4F841A]"
      data-index="tutorial"
      ref={refSection}
    >
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-col items-center">
          <div className="flex justify-center mb-[24px]">
            <div className="font-semibold flex justify-center w-fit items-center py-[10px] px-[27px] border-2 rounded-full border-primary text-primary">
              Tutorial
            </div>
          </div>

          <h2 className="text-center mb-2">
            <span
              className="text-3xl md:text-4xl font-semibold"
              style={{
                background:
                  "linear-gradient(180deg, #0A1C2E 30%, #B9C8D9 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                color: "transparent",
                fontWeight: "bold",
              }}
            >
              Easy guide to using e-Signature
            </span>
          </h2>
          <p className="text-center text-gray-600 mb-9">
            Simple steps to get you signing in no time.{" "}
          </p>

          {listTutorial.map((tutorial, index) => (
            <div
              key={tutorial.id}
              className={`
              flex flex-col items-center gap-11 
              rounded-3xl 
              px-4 py-6 md:px-8 md:py-8 
              mb-11 last:mb-0
              ${index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"}
              relative overflow-hidden
            `}
              style={{
                backgroundImage: `url(${FeatureBackground.src})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="md:w-1/2">
                <div className="rounded-lg">
                  <Image
                    src={VideoIcon}
                    alt="Document upload interface"
                    width={400}
                    height={300}
                    className="w-full h-auto"
                  />
                </div>
              </div>

              <div className="md:w-1/2">
                <div className="inline-block px-2 md:px-7 py-1 md:py-2 border-[1px] rounded-full text-sm md:text-base font-medium mb-4 md:mb-10">
                  {tutorial.key}
                </div>
                <h2 className="text-xl md:text-2xl font-semibold mb-1 md:mb-3">
                  {tutorial.label}
                </h2>
                <p className="text-gray-600 mb-4 md:mb-12">
                  {tutorial.description}
                </p>
                <Button
                  className="p-[10px] sm:p-[16px] text-[12px]"
                  style={{
                    borderRadius: "30px",
                  }}
                >
                  Watch Tutorial
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TutorialSection;
